import Matter from "matter-js";
import bikerImg from "../../assets/images/biker.png";
import rectWrapper from "../wrapper/rectWrapper";

export default function createBrakeView() {
  const Composite = Matter.Composite,
    Bodies = Matter.Bodies,
    Body = Matter.Body;

  const group = Body.nextGroup(true);

  const bike = Composite.create({ label: "Bike", id: 0 }),
    biker = Bodies.rectangle(750, 400, 123, 291, {
      id: 1,
      label: "Biker",
      isStatic: true,
      collisionFilter: {
        group: group,
      },
      render: {
        sprite: {
          texture: bikerImg,
          xScale: 1.25,
          yScale: 1.25,
        },
      },
    });

  const brakeHandle = new rectWrapper(795, 318, 45, 5, {
    id: 2,
    label: "Brake Handle",
    isStatic: true,
    collisionFilter: {
      group: group,
    },
    angle: 340 * (Math.PI / 180),
  });

  Body.setCentre(brakeHandle.body, {
    x: brakeHandle.body.position.x - brakeHandle.width / 2,
    y: brakeHandle.body.position.y + brakeHandle.height / 2,
  });

  const brake = Bodies.rectangle(765, 285, 3, 50, {
    id: 3,
    label: "Brake",
    isStatic: true,
    collisionFilter: {
      group: group,
    },
  });

  const brakeSystem = Composite.create({ label: "Brake System", id: 4 });

  const brakeSystemLink = Bodies.rectangle(765, 290, 10, 3, {
    id: 5,
    label: "Brake System Link",
    isStatic: true,
    collisionFilter: {
      group: group,
    },
  });

  const brakeLeftCaliper = Bodies.rectangle(760, 285, 4, 15, {
    id: 6,
    label: "Brake Left Caliper",
    isStatic: true,
    collisionFilter: {
      group: group,
    },
  });

  const brakeRightCaliper = Bodies.rectangle(770, 285, 4, 15, {
    id: 7,
    label: "Brake Right Caliper",
    isStatic: true,
    collisionFilter: {
      group: group,
    },
  });

  Composite.add(brakeSystem, [
    brakeSystemLink,
    brakeLeftCaliper,
    brakeRightCaliper,
  ]);

  Composite.add(bike, [biker, brakeHandle.body, brake, brakeSystem]);

  return bike;
}
