import { Gauge } from "gaugeJS";
import { gaugeOpts } from "../engine/const";

const createSpeedInput = (prefix) => {
  const speedContainer = document.createElement("div");
  const canvas = document.createElement("canvas");
  const speedLabel = document.createElement("label");
  const selectContainer = document.createElement("div");
  const select = document.createElement("select");
  const label = document.createElement("label");

  label.setAttribute("for", prefix + "unit-select");
  label.setAttribute("class", "form-label");
  label.innerHTML = "Unité de vitesse";

  select.setAttribute("class", "form-select form-control");
  select.setAttribute("id", prefix + "unit-select");

  for (let i = 0; i < 2; i++) {
    let option = document.createElement("option");
    option.setAttribute("value", i);
    option.innerHTML = i == 0 ? "km/h" : "m/s";
    if (i == 0) {
      option.setAttribute("selected", "");
    }
    select.appendChild(option);
  }

  selectContainer.appendChild(label);
  selectContainer.appendChild(select);

  speedContainer.setAttribute("id", prefix + "speed-container");
  canvas.setAttribute("id", prefix + "speedometer");
  speedLabel.setAttribute("id", prefix + "speedometer-label");
  speedLabel.innerHTML = "0km/h";
  speedLabel.setAttribute("for", prefix + "speedometer");
  speedLabel.setAttribute("class", "speedometer-label");
  speedContainer.appendChild(canvas);
  speedContainer.appendChild(speedLabel);
  speedContainer.appendChild(selectContainer);
  return speedContainer;
};

const createSpeedometerCanvas = (prefix) => {
  var target = document.getElementById(prefix + "speedometer"); // your canvas element
  var gauge = new Gauge(target).setOptions(gaugeOpts); // create sexy gauge!
  gauge.maxValue = 60; // set max gauge value
  gauge.setMinValue(0); // Prefer setter over gauge.minValue = 0
  gauge.set(0); // set actual value
};

const createDistanceInput = (prefix) => {
  const distanceContainer = document.createElement("div");
  const mdistanceContainer = document.createElement("div");
  const kmdistanceContainer = document.createElement("div");

  let label = document.createElement("label");
  let input = document.createElement("input");

  label.setAttribute("for", prefix + "m-distance");
  label.setAttribute("class", "form-label");
  label.innerHTML = "Distance en mètres";

  input.setAttribute("class", "distance form-control");
  input.setAttribute("id", prefix + "m-distance");
  input.disabled = true;

  mdistanceContainer.appendChild(label);
  mdistanceContainer.appendChild(input);

  label = document.createElement("label");
  input = document.createElement("input");

  label.setAttribute("for", prefix + "km-distance");
  label.setAttribute("class", "form-label");
  label.innerHTML = "Distance en Km";

  input.setAttribute("class", "distance form-control");
  input.setAttribute("id", prefix + "km-distance");
  input.disabled = true;

  kmdistanceContainer.appendChild(label);
  kmdistanceContainer.appendChild(input);

  distanceContainer.appendChild(mdistanceContainer);
  distanceContainer.appendChild(kmdistanceContainer);

  return distanceContainer;
};

export { createSpeedInput, createSpeedometerCanvas, createDistanceInput };
