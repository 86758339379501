import Matter from "matter-js";
import { Gauge } from "gaugeJS";
import { kmhToMs, msToKmh, scrollBackground } from "../utils";
import { gaugeOpts, gaugeOptsMs, mGaugeVal, kmGaugeVal } from "../const.js";
const Body = Matter.Body;

export default function init(composite, runner) {
  let tick = 0;
  // timeCounter in 0.1s
  let bikeWeight = 90;
  let timeCounter = 0;
  let brakeValue = 0;
  let calipers = [];
  let brakeHandle = null;
  let background = null;
  let startingSpeed = 0;
  let currentSpeed = 0;
  let currentSpeedUnit = 0;
  let bodies = Matter.Composite.allBodies(composite);
  for (let i = 0; i < bodies.length; i += 1) {
    let body = bodies[i];
    if (body.label.includes("Caliper")) {
      calipers.push(body);
    }
    if (body.label == "Brake Handle") {
      brakeHandle = body;
    }
    if (body.label == "Background") {
      background = body;
    }
  }

  let speedValue = document.getElementById("brake-engine-speed-value");
  let speedRange = document.getElementById("brake-engine-speed-range");
  speedRange.addEventListener("input", () => {
    speedValue.innerHTML = speedRange.value;
    startingSpeed = speedRange.value;
  });

  let brakeValueToDisplay = document.getElementById("brake-engine-brake-value");
  let brakeRange = document.getElementById("brake-engine-brake-range");
  brakeRange.addEventListener("input", () => {
    brakeValueToDisplay.innerHTML = brakeRange.value;
    brakeValue = brakeRange.value;
    updateCaliperPosition(calipers, brakeValue);
    updateBrakeHandlePosition(brakeHandle, brakeValue);
  });
  let unitSelect = document.getElementById("brake-engine-unit-select");
  unitSelect.addEventListener("input", () => {
    currentSpeedUnit = parseInt(unitSelect.value);
  });
  let decelerationInput = document.getElementById("brake-engine-deceleration");
  let speedMeter = document.getElementById("brake-engine-speedometer");
  let speedMeterLabel = document.getElementById(
    "brake-engine-speedometer-label"
  );
  let mbrakingDistance = document.getElementById("brake-engine-m-distance");
  let kmbrakingDistance = document.getElementById("brake-engine-km-distance");
  Matter.Events.on(runner, "afterTick", function () {
    tick += 1;
    if (speedMeter !== null && speedMeter !== undefined) {
      let gauge = new Gauge(speedMeter).setOptions(
        currentSpeedUnit === 0 ? gaugeOpts : gaugeOptsMs
      );
      gauge.maxValue = currentSpeedUnit === 0 ? kmGaugeVal.max : mGaugeVal.max;
      gauge.setMinValue(0);
      currentSpeed =
        currentSpeedUnit === 0
          ? msToKmh(
              getCurrentSpeed(
                brakeValue,
                bikeWeight,
                startingSpeed,
                timeCounter / 10
              )
            )
          : getCurrentSpeed(
              brakeValue,
              bikeWeight,
              startingSpeed,
              timeCounter / 10
            );
      gauge.set(currentSpeed);
      speedMeterLabel.innerHTML =
        currentSpeedUnit === 0 ? currentSpeed + "Km/h" : currentSpeed + "m/s";
      speedMeterLabel.setAttribute("class", "speedometer-label");
    }

    if (parseInt(startingSpeed) !== 0) {
      if (parseInt(brakeValue) !== 0) {
        if (tick >= 6) {
          // reset tick after every 0.1sec, and add 1 to counter for distance
          tick = 0;
          timeCounter += 1;
        }
        decelerationInput.value = getDeceleration(brakeValue, bikeWeight);

        mbrakingDistance.value = getBrakingDistance(
          brakeValue,
          bikeWeight,
          startingSpeed
        );
        kmbrakingDistance.value =
          getBrakingDistance(brakeValue, bikeWeight, startingSpeed) / 1000;
      } else {
        decelerationInput.value = "";
        currentSpeed = 0;
        mbrakingDistance.value = "";
        kmbrakingDistance.value = "";
      }
      scrollBackground(
        background,
        decelerationInput.value != "" ? currentSpeed : startingSpeed
      );
    } else {
      tick = 0;
      timeCounter = 0;
    }
  });
}

const updateCaliperPosition = (calipers, brakeValue) => {
  calipers.forEach((caliper) => {
    if (caliper.label.includes("Left")) {
      Body.setPosition(caliper, {
        x: 760 + 1 * (brakeValue / 50),
        y: caliper.position.y,
      });
    } else {
      Body.setPosition(caliper, {
        x: 770 - 1 * (brakeValue / 50),
        y: caliper.position.y,
      });
    }
  });
};

const updateBrakeHandlePosition = (brakeHandle, brakeValue) => {
  let degrees = 340 + 25 * (brakeValue / 100);
  Body.setAngle(brakeHandle, degrees * (Math.PI / 180));
};

const getDeceleration = (brakeValue, bikeWeight) => {
  return -brakeValue / bikeWeight;
};

const getCurrentSpeed = (
  brakeValue,
  bikeWeight,
  startingSpeed,
  timetravelled
) => {
  let deceleration = getDeceleration(brakeValue, bikeWeight);
  let res = kmhToMs(startingSpeed) + deceleration * timetravelled;
  return res < 0 ? 0 : res;
};

const getBrakingDistance = (brakeValue, bikeWeight, startingSpeed) => {
  let startingSpeedInMs = kmhToMs(startingSpeed);
  let deceleration = getDeceleration(brakeValue, bikeWeight);
  return 0 * 0 - (startingSpeedInMs * startingSpeedInMs) / (2 * deceleration);
};
