// import { wheelSizes } from "./const";

import { createDistanceInput, createSpeedInput } from "./generalInputs";

export default function createTopViewInputs() {
  const inputContainer = document.createElement("div");
  inputContainer.setAttribute("class", "input-container");
  const slider = createSpeedSlider();
  const brakeSlider = createBrakeSlider();
  const distance = createDistanceInput("brake-engine-");
  const speed = createSpeedInput("brake-engine-");
  const deceleration = createDecelerationInput();
  inputContainer.appendChild(slider);
  inputContainer.appendChild(brakeSlider);
  inputContainer.appendChild(speed);
  inputContainer.appendChild(deceleration);
  inputContainer.appendChild(distance);
  return inputContainer;
}

const createSpeedSlider = () => {
  const rangeContainer = document.createElement("div");
  const label = document.createElement("label");
  const slider = document.createElement("input");
  const value = document.createElement("span");

  label.setAttribute("for", "brake-engine-speed-range");
  label.setAttribute("class", "form-label");
  label.innerHTML = "Vitesse de départ";

  slider.setAttribute("type", "range");
  slider.setAttribute("class", "form-range form-control");
  slider.setAttribute("id", "brake-engine-speed-range");
  slider.setAttribute("value", 0);
  slider.setAttribute("min", 0);
  slider.setAttribute("max", 40);
  slider.setAttribute("setp", 1);

  value.setAttribute("class", "form-label");
  value.setAttribute("id", "brake-engine-speed-value");
  value.innerHTML = slider.value;

  rangeContainer.appendChild(label);
  rangeContainer.appendChild(slider);
  rangeContainer.appendChild(value);

  return rangeContainer;
};

const createBrakeSlider = () => {
  const rangeContainer = document.createElement("div");
  const label = document.createElement("label");
  const slider = document.createElement("input");
  const value = document.createElement("span");

  label.setAttribute("for", "brake-engine-brake-range");
  label.setAttribute("class", "form-label");
  label.innerHTML = "Force de freinage en Newton";

  slider.setAttribute("type", "range");
  slider.setAttribute("class", "form-range form-control");
  slider.setAttribute("id", "brake-engine-brake-range");
  slider.setAttribute("value", 0);
  slider.setAttribute("min", 0);
  slider.setAttribute("max", 100);
  slider.setAttribute("step", 1);

  value.setAttribute("class", "form-label");
  value.setAttribute("id", "brake-engine-brake-value");
  value.innerHTML = slider.value;

  rangeContainer.appendChild(label);
  rangeContainer.appendChild(slider);
  rangeContainer.appendChild(value);

  return rangeContainer;
};

const createDecelerationInput = () => {
  const decelerationContainer = document.createElement("div");
  const label = document.createElement("label");
  const input = document.createElement("input");

  label.setAttribute("for", "brake-engine-deceleration");
  label.setAttribute("class", "form-label");
  label.innerHTML = "Décélération en m/s";

  input.setAttribute("class", "deceleration form-control");
  input.setAttribute("id", "brake-engine-deceleration");
  input.disabled = true;

  decelerationContainer.appendChild(label);
  decelerationContainer.appendChild(input);

  return decelerationContainer;
};
