import { wheelSizes } from "../engine/const";
import { createDistanceInput, createSpeedInput } from "./generalInputs";

export default function createInputs() {
  const inputContainer = document.createElement("div");
  inputContainer.setAttribute("class", "input-container");
  const slider = createSlider();
  const select = createSelect();
  const distance = createDistanceInput("");
  const speed = createSpeedInput("");
  inputContainer.appendChild(select);
  inputContainer.appendChild(slider);
  inputContainer.appendChild(speed);
  inputContainer.appendChild(distance);

  return inputContainer;
}

const createSelect = () => {
  const selectContainer = document.createElement("div");
  const select = document.createElement("select");
  const label = document.createElement("label");

  label.setAttribute("for", "wheel-select");
  label.setAttribute("class", "form-label");
  label.innerHTML = "Taille des roues";

  select.setAttribute("class", "form-select form-control");
  select.setAttribute("id", "wheel-select");

  for (let i = 0; i < wheelSizes.length; i++) {
    let option = document.createElement("option");
    option.setAttribute("value", i);
    option.innerHTML = wheelSizes[i].name;
    if (i == 2) {
      option.setAttribute("selected", "");
    }
    select.appendChild(option);
  }

  selectContainer.appendChild(label);
  selectContainer.appendChild(select);

  return selectContainer;
};

const createSlider = () => {
  const rangeContainer = document.createElement("div");
  const label = document.createElement("label");
  const slider = document.createElement("input");
  const value = document.createElement("span");

  label.setAttribute("for", "spin-range");
  label.setAttribute("class", "form-label");
  label.innerHTML = "Tour/min";

  slider.setAttribute("type", "range");
  slider.setAttribute("class", "form-range form-control");
  slider.setAttribute("id", "spin-range");
  slider.setAttribute("value", 0);
  slider.setAttribute("min", 0);
  slider.setAttribute("max", 120);
  slider.setAttribute("step", 1);

  value.setAttribute("class", "form-label");
  value.setAttribute("id", "spin-value");
  value.innerHTML = slider.value;

  rangeContainer.appendChild(label);
  rangeContainer.appendChild(slider);
  rangeContainer.appendChild(value);

  return rangeContainer;
};
