import Matter from "matter-js";
import { Gauge } from "gaugeJS";
import {
  wheelSizes,
  idsToRotate,
  gearPosition,
  frontWheelPosition,
  backWheelPosition,
  gearSize,
  gaugeOpts,
  mGaugeVal,
  kmGaugeVal,
  gaugeOptsMs,
} from "../const.js";
import { rotateContinually } from "../utils.js";

const Body = Matter.Body,
  Composite = Matter.Composite;
const degree = 0.4;
const radians = degree * (Math.PI / 180);

let circleBodies = [];
let chainBodies = [];
let chainComposite = null;
let rotationSpeed = 0;
let currentWheelSize = wheelSizes[2].val;
let counterForDistance = 0;
let tick = 0;
let markers = [];
let markerConstraints = [];
let wheelAndGearConstraints = [];
let currentScaleLvl = 2;
let currentGearPos = { x: gearPosition.x, y: gearPosition.y };
let currentBackWheelPos = { x: backWheelPosition.x, y: backWheelPosition.y };
let currentGearSize = gearSize;
let cranckset = null;
let pedals = [];
let crancksetAndPedalsConstraints = [];
let currentSpeedUnit = 0;
let crancksetComposite = null;
let bikeFrame = null;

const initBike = (composite, runner) => {
  let bodies = Composite.allBodies(composite);
  let composites = Composite.allComposites(composite);
  let constraints = Composite.allConstraints(composite);
  for (let i = 0; i < bodies.length; i += 1) {
    let body = bodies[i];
    if (idsToRotate.includes(body.id)) {
      circleBodies.push(body);
    }
    if (body.label === "Chain Joint") {
      chainBodies.push(body);
    }
    if (body.label === "Wheel Markers") {
      markers.push(body);
    }
    if (body.label === "Cranckset") {
      cranckset = body;
    }
    if (body.label.includes("Pedal")) {
      pedals.push(body);
    }
    if (body.label === "Bike Frame") {
      bikeFrame = body;
    }
  }
  for (let i = 0; i < composites.length; i += 1) {
    let composite = composites[i];
    if (composite.label === "Chain Composite") {
      chainComposite = composite;
    }
    if (composite.label === "Cranckset Composite") {
      crancksetComposite = composite;
    }
  }
  for (let i = 0; i < constraints.length; i += 1) {
    let constraint = constraints[i];
    if (constraint.label === "Marker Constraint") {
      markerConstraints.push(constraint);
    }
    if (
      constraint.label === "Wheel Constraint" ||
      constraint.label === "Gear Constraint"
    ) {
      wheelAndGearConstraints.push(constraint);
    }
    if (
      constraint.label === "Cranckset Constraint" ||
      constraint.label.includes("Pedal Constraint")
    ) {
      crancksetAndPedalsConstraints.push(constraint);
    }
  }

  let wheelSelect = document.getElementById("wheel-select");
  wheelSelect.addEventListener("input", () => {
    currentWheelSize = wheelSizes[wheelSelect.value].val;
    updateWheelAndGearSize(
      circleBodies,
      wheelAndGearConstraints,
      wheelSizes[wheelSelect.value].size
    );
    updateWheelMarkers(
      markers,
      markerConstraints,
      wheelSizes[wheelSelect.value].size
    );
    updateChainSize(chainComposite, wheelSizes[wheelSelect.value].size);
    updateCrancksetAndPedals(
      crancksetComposite,
      crancksetAndPedalsConstraints,
      wheelSizes[wheelSelect.value].size
    );
    udpdateBikeFrame(wheelSizes[wheelSelect.value].size / 100);

    currentScaleLvl = wheelSelect.value;
  });

  let unitSelect = document.getElementById("unit-select");
  unitSelect.addEventListener("input", () => {
    currentSpeedUnit = parseInt(unitSelect.value);
  });

  let rotationValue = document.getElementById("spin-value");
  let rotationRange = document.getElementById("spin-range");
  let speedMeter = document.getElementById("speedometer");
  let speedMeterLabel = document.getElementById("speedometer-label");
  let distanceMeter = document.getElementById("m-distance");
  let kmdistanceMeter = document.getElementById("km-distance");

  Matter.Events.on(runner, "afterTick", function () {
    tick += 1;

    if (rotationRange !== null && rotationRange !== undefined) {
      rotationRange.addEventListener("input", () => {
        rotationValue.innerHTML = rotationRange.value;
        rotationSpeed = rotationRange.value;
      });
    }

    if (speedMeter !== null && speedMeter !== undefined) {
      let gauge = new Gauge(speedMeter).setOptions(
        currentSpeedUnit === 0 ? gaugeOpts : gaugeOptsMs
      );
      gauge.maxValue = currentSpeedUnit === 0 ? kmGaugeVal.max : mGaugeVal.max;
      gauge.setMinValue(0);
      let currentSpeed =
        currentSpeedUnit === 0 ? getCurrentSpeed() : kmhToMs(getCurrentSpeed());
      gauge.set(currentSpeed);
      speedMeterLabel.innerHTML =
        currentSpeedUnit === 0 ? currentSpeed + "Km/h" : currentSpeed + "m/s";
      speedMeterLabel.setAttribute("class", "speedometer-label");
    }

    if (distanceMeter !== null && distanceMeter !== undefined) {
      distanceMeter.value = getDistanceTraveled();
    }

    if (kmdistanceMeter !== null && kmdistanceMeter !== undefined) {
      kmdistanceMeter.value = getDistanceTraveled() / 1000;
    }

    if (parseInt(rotationSpeed) !== 0) {
      if (tick >= 6) {
        // reset tick after every 0.1sec, and add 1 to counter for distance
        tick = 0;
        counterForDistance += 1;
      }
      rotateContinually(
        [...circleBodies, crancksetComposite],
        rotationSpeed / 3 / 100,
        pedals,
        currentScaleLvl != 2 ? currentGearPos.x : null,
        currentScaleLvl != 2 ? currentGearPos.y : null
      );
      initChain(chainBodies, rotationSpeed / 3);
    } else {
      circleBodies.forEach((circle) => {
        circle.isStatic = true;
      });
      cranckset.isStatic = true;
      tick = 0;
      counterForDistance = 0;
    }
  });
};

// Change constants for real values
const initChain = (chainBodies, rotationSpeed) => {
  let tresholdValue = getTresholdValueForChain();
  chainBodies.forEach((elm) => {
    if (
      elm.position.y === currentGearPos.y - currentGearSize &&
      elm.position.x <= currentGearPos.x
    ) {
      Body.setPosition(elm, {
        x: elm.position.x + rotationSpeed * 0.2,
        y: elm.position.y,
      });
    } else if (
      elm.position.y == currentGearPos.y + currentGearSize &&
      elm.position.x >= currentBackWheelPos.x
    ) {
      Body.setPosition(elm, {
        x: elm.position.x - rotationSpeed * 0.2,
        y: elm.position.y,
      });
    } else if (
      elm.position.x > currentGearPos.x &&
      elm.position.y < currentGearPos.y + currentGearSize
    ) {
      let x =
        Math.cos(radians * rotationSpeed) *
          (elm.position.x - currentGearPos.x) -
        Math.sin(radians * rotationSpeed) *
          (elm.position.y - currentGearPos.y) +
        currentGearPos.x;
      let y =
        Math.sin(radians * rotationSpeed) *
          (elm.position.x - currentGearPos.x) +
        Math.cos(radians * rotationSpeed) *
          (elm.position.y - currentGearPos.y) +
        currentGearPos.y;
      if (
        y > currentGearPos.y + currentGearSize ||
        Math.abs(y - (currentGearPos.y + currentGearSize)) < tresholdValue
      ) {
        y = currentGearPos.y + currentGearSize;
      }
      Body.setPosition(elm, {
        x: x,
        y: y,
      });
    } else if (
      elm.position.x < currentBackWheelPos.x &&
      elm.position.y > currentBackWheelPos.y - currentGearSize
    ) {
      let x =
        Math.cos(radians * rotationSpeed) *
          (elm.position.x - currentBackWheelPos.x) -
        Math.sin(radians * rotationSpeed) *
          (elm.position.y - currentBackWheelPos.y) +
        currentBackWheelPos.x;
      let y =
        Math.sin(radians * rotationSpeed) *
          (elm.position.x - currentBackWheelPos.x) +
        Math.cos(radians * rotationSpeed) *
          (elm.position.y - currentBackWheelPos.y) +
        currentBackWheelPos.y;
      if (
        y < currentBackWheelPos.y - currentGearSize ||
        Math.abs(y - (currentBackWheelPos.y - currentGearSize)) < tresholdValue
      ) {
        y = currentBackWheelPos.y - currentGearSize;
      }
      Body.setPosition(elm, {
        x: x,
        y: y,
      });
    }
  });
};

const getTresholdValueForChain = () => {
  switch (parseInt(currentScaleLvl)) {
    case 0:
      return 0.2;
    case 1:
      return 0.4;
    case 2:
      return 0.5;
    case 3:
      return 1.25;
    case 4:
      return 3;
  }
};

const updateWheelAndGearSize = (
  elements,
  wheelAndGearConstraints,
  wheelSize
) => {
  elements.forEach((elm) => {
    scaleWheelAndGear(elm, wheelSize / 100);
    updateWheelAndGearPos(elm, wheelSize);
  });
  wheelAndGearConstraints.forEach((constraint) => {
    updateWheelAndGearConstraint(constraint);
  });
};

const scaleWheelAndGear = (elm, scale) => {
  if (elm.label === "Gear" || elm.label === "Wheel Gear") {
    elm.render.sprite.xScale = 0.5 * scale;
    elm.render.sprite.yScale = 0.5 * scale;
    currentGearSize = elm.circleRadius * scale;
  } else {
    elm.render.sprite.xScale = scale;
    elm.render.sprite.yScale = scale;
  }
  Body.scale(elm, scale, scale);
};

const updateWheelAndGearPos = (elm, wheelSize) => {
  let xVal = elm.position.x;
  if (elm.label === "Gear") {
    xVal = gearPosition.x - (wheelSizes[2].size - wheelSize) * 1.5;
    currentGearPos.x = xVal;
    currentGearPos.y = backWheelPosition.y + (wheelSizes[2].size - wheelSize);
  } else if (elm.label === "Front Wheel") {
    xVal = frontWheelPosition.x - (wheelSizes[2].size - wheelSize) * 3;
  } else if (elm.label === "Back Wheel") {
    currentBackWheelPos.x = xVal;
    currentBackWheelPos.y =
      backWheelPosition.y + (wheelSizes[2].size - wheelSize);
  }
  Body.setPosition(elm, {
    x: xVal,
    y: backWheelPosition.y + (wheelSizes[2].size - wheelSize),
  });
};

const updateWheelAndGearConstraint = (constraint) => {
  constraint.pointB.x = constraint.bodyA.position.x;
  constraint.pointB.y = constraint.bodyA.position.y;
};

const updateCrancksetAndPedals = (
  crancksetComposite,
  crancksetAndPedalsConstraints,
  wheelSize
) => {
  if (currentScaleLvl !== 2) {
    // Scale back element to 100% before scaling to right %
    Composite.scale(
      crancksetComposite,
      100 / wheelSizes[currentScaleLvl].size,
      100 / wheelSizes[currentScaleLvl].size,
      {
        x: 240,
        y: 580,
      }
    );
  }
  Composite.scale(crancksetComposite, wheelSize / 100, wheelSize / 100, {
    x: 240,
    y: 580,
  });
  crancksetAndPedalsConstraints.forEach((elm) => {
    updateCrancksetAndPedalsConstraint(elm, wheelSize / 100, wheelSize);
  });
};

const updateCrancksetAndPedalsConstraint = (constraint, scale) => {
  if (constraint.label === "Cranckset Constraint") {
    updateWheelAndGearConstraint(constraint);
  } else {
    if (constraint.label === "Back Pedal Constraint") {
      constraint.pointB.x = -(115 * scale) / 2;
      // constraint.pointB.y = constraint.bodyB.position.y;
    } else {
      constraint.pointB.x = (115 * scale) / 2;
      // constraint.pointB.y = constraint.bodyB.position.y;
    }
  }
};

const updateWheelMarkers = (elements, markerConstraints, wheelSize) => {
  elements.forEach((elm) => {
    scaleMarkers(elm, wheelSize / 100, wheelSize);
  });
  markerConstraints.forEach((constraint) => {
    updateMarkersConstraints(constraint, wheelSize);
  });
};

const scaleMarkers = (elm, scale) => {
  Body.scale(elm, scale, scale);
};

const updateMarkersConstraints = (elm, wheelSize) => {
  elm.pointB.x = 0;
  elm.pointB.y = -wheelSize + 4;
};

const updateChainSize = (chainComposite, wheelSize) => {
  scaleChain(chainComposite, wheelSize / 100, wheelSize);
};

// TODO
const scaleChain = (elm, scale) => {
  if (currentScaleLvl !== 2) {
    // Scale back element to 100% before scaling to right %
    Matter.Composite.scale(
      elm,
      100 / wheelSizes[currentScaleLvl].size,
      100 / wheelSizes[currentScaleLvl].size,
      {
        x: 240,
        y: 580,
      }
    );
  }

  Matter.Composite.scale(elm, scale, scale, {
    x: 240,
    y: 580,
  });
};

// TODO
const udpdateBikeFrame = (scale) => {
  bikeFrame.render.sprite.xScale = 0.5 * scale;
  bikeFrame.render.sprite.yScale = 0.5 * scale;
  Body.setPosition(bikeFrame, {
    x: currentGearPos.x + 25 * scale,
    y: currentGearPos.y - 100 * scale,
  });
};

const getWheelCircumference = () => {
  return (currentWheelSize / 100) * Math.PI;
};

const getCurrentSpeed = () => {
  const currentCircumference = getWheelCircumference();
  return Math.round(currentCircumference * rotationSpeed * (1 / 6) * 10) / 10;
};

const getDistanceTraveled = () => {
  const currentSpeed = getCurrentSpeed();
  return (
    Math.round(currentSpeed * (1000 / 36000) * counterForDistance * 10) / 10
  );
};

const kmhToMs = (val) => {
  return val * (5 / 18);
};

const msToKmh = (val) => {
  return val * 3.6;
};

export { initBike, kmhToMs, msToKmh, initChain };
