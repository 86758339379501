import Matter from "matter-js";
import rectWrapper from "../wrapper/rectWrapper";
import gearImg from "../../assets/images/gear.png";
import { gearSize, gearPosition, gearIds } from "../const";
export default function createGear(group) {
  const Bodies = Matter.Bodies,
    Composite = Matter.Composite,
    Constraint = Matter.Constraint;

  const gear = Composite.create({ label: "Gear Composite" }),
    body = Bodies.circle(gearPosition.x, gearPosition.y, gearSize, {
      id: gearIds[1],
      label: "Gear",
      collisionFilter: {
        group: group,
      },
      render: {
        sprite: {
          texture: gearImg,
          xScale: 0.5,
          yScale: 0.5,
        },
      },
    });

  let crancksetComposite = Composite.create({ label: "Cranckset Composite" }),
    cranckset = new rectWrapper(gearPosition.x, gearPosition.y, 115, 5, {
      // id: gearIds[1],
      label: "Cranckset",
      collisionFilter: {
        group: group,
      },
      // isStatic: true,
    });

  let backPedal = Bodies.rectangle(
    gearPosition.x - cranckset.width / 2,
    gearPosition.y,
    20,
    10,
    {
      // id: gearIds[1],
      label: "Back Pedal",
      collisionFilter: {
        group: group,
      },
      isStatic: true,
    }
  );

  let frontPedal = Bodies.rectangle(
    gearPosition.x + cranckset.width / 2,
    gearPosition.y,
    20,
    10,
    {
      // id: gearIds[1],
      label: "Front Pedal",
      collisionFilter: {
        group: group,
      },
      isStatic: true,
    }
  );

  const constraint = Constraint.create({
    bodyA: body,
    pointA: { x: 0, y: 0 },
    pointB: { x: gearPosition.x, y: gearPosition.y },
    stiffness: 1,
    length: 0,
    label: "Gear Constraint",
  });

  const crancksetConstraint = Constraint.create({
    bodyA: cranckset.body,
    pointA: { x: 0, y: 0 },
    pointB: { x: gearPosition.x, y: gearPosition.y },
    stiffness: 1,
    length: 0,
    label: "Cranckset Constraint",
  });

  const backPedalConstraint = Constraint.create({
    bodyA: backPedal,
    bodyB: cranckset.body,
    pointB: { x: -cranckset.width / 2, y: 0 },
    stiffness: 1,
    length: 0,
    label: "Back Pedal Constraint",
  });

  const frontPedalConstraint = Constraint.create({
    bodyA: frontPedal,
    bodyB: cranckset.body,
    pointB: { x: cranckset.width / 2, y: 0 },
    stiffness: 1,
    length: 0,
    label: "Front Pedal Constraint",
  });
  Composite.add(crancksetComposite, [
    cranckset.body,
    crancksetConstraint,
    backPedal,
    backPedalConstraint,
    frontPedal,
    frontPedalConstraint,
  ]);

  Composite.add(gear, [body, constraint, crancksetComposite]);

  return gear;
}
