import Matter from "matter-js";
import {
  chainStartingId,
  chainJointSize,
  gearPosition,
  backWheelPosition,
} from "../const";
const Composite = Matter.Composite,
  Composites = Matter.Composites,
  Constraint = Matter.Constraint,
  Bodies = Matter.Bodies;
export default function createChain(
  gear,
  wheel,
  group,
  gearSize,
  baseGearSize
) {
  let chainStack = null;

  if (gearSize < baseGearSize) {
    chainStack = createStackForSmallerSprocket(
      gear,
      wheel,
      group,
      gearSize,
      baseGearSize
    );
  } else if (gearSize > baseGearSize) {
    chainStack = createStackForLargerSprocket(
      gear,
      wheel,
      group,
      gearSize,
      baseGearSize
    );
  } else {
    chainStack = createStackForRegularSprocket(gear, wheel, group, gearSize);
  }

  const constraints = [];

  for (let i = 0; i < chainStack.bodies.length - 1; i++) {
    const constraint = Constraint.create({
      bodyA: chainStack.bodies[i],
      bodyB: chainStack.bodies[i + 1],
      stiffness: 1,
    });
    constraints.push(constraint);
  }

  const constraint2 = Constraint.create({
    bodyA: chainStack.bodies[chainStack.bodies.length - 1],
    bodyB: chainStack.bodies[0],
    stiffness: 1,
  });
  constraints.push(constraint2);
  chainStack.label = "Chain Composite";

  Composite.add(chainStack, [...constraints]);

  return chainStack;
}

const createStackForRegularSprocket = (gear, wheel, group, gearSize) => {
  const xStartPos = wheel.bodies[1].position.x - chainJointSize;
  const yStartPos = wheel.bodies[1].position.y - gearSize - chainJointSize;
  let currentCounterValForInterval = 0;
  let part = 1;
  let xPrev = xStartPos;
  let secondStartingX = 0;

  const chainStack = Composites.stack(
    xStartPos,
    yStartPos,
    38,
    1,
    3,
    0,
    function (x, y, counter) {
      if (xPrev < gearPosition.x - chainJointSize && part == 1) {
        if (
          xStartPos + (counter + 1) * 12.51 >
          gearPosition.x - chainJointSize
        ) {
          part = 2;
          y = gearPosition.y - gearSize - chainJointSize;
          currentCounterValForInterval = counter;
        }
      } else if (part == 2) {
        x =
          gearSize *
            Math.cos(0.425 * (counter - currentCounterValForInterval) - 1.65) +
          gear.bodies[0].position.x -
          chainJointSize;
        y =
          gearSize *
            Math.sin(0.425 * (counter - currentCounterValForInterval) - 1.65) +
          gear.bodies[0].position.y -
          chainJointSize;
        if (
          gearSize *
            Math.sin(
              0.425 * (counter - currentCounterValForInterval + 1) - 1.65
            ) +
            gear.bodies[0].position.y -
            chainJointSize <
          y
        ) {
          part = 3;
          y = gearPosition.y + gearSize - chainJointSize;
          currentCounterValForInterval = counter;
        }
      } else if (part == 3) {
        if (counter - currentCounterValForInterval == 1) {
          secondStartingX = x;
        }

        x = secondStartingX - (counter - currentCounterValForInterval) * 12.51;
        y = gearPosition.y + gearSize - chainJointSize;

        if (
          secondStartingX -
            (counter - currentCounterValForInterval + 1) * 12.51 <
          backWheelPosition.x - chainJointSize
        ) {
          part = 4;
          currentCounterValForInterval = counter;
        }
      } else if (part == 4) {
        x =
          gearSize *
            Math.cos(0.425 * (counter - currentCounterValForInterval) + 1.275) +
          wheel.bodies[1].position.x -
          chainJointSize;
        y =
          gearSize *
            Math.sin(0.425 * (counter - currentCounterValForInterval) + 1.275) +
          wheel.bodies[1].position.y -
          chainJointSize;
      }
      xPrev = x;
      return Bodies.circle(x, y, chainJointSize, {
        id: chainStartingId + counter,
        label: "Chain Joint",
        isStatic: true,
        collisionFilter: {
          group: group,
        },
      });
    }
  );
  return chainStack;
};

const createStackForSmallerSprocket = (
  gear,
  wheel,
  group,
  gearSize,
  baseGearSize
) => {
  let xInterval,
    yInterval,
    nbrOfElm,
    angle,
    part4BaseAngle = null;
  if (gearSize == 15) {
    xInterval = 12.51;
    yInterval = 1.25;
    nbrOfElm = 35;
    angle = 0.75;
    part4BaseAngle = 1;
  } else {
    xInterval = 12.51;
    yInterval = 0.55;
    nbrOfElm = 36;
    angle = 0.6;
    part4BaseAngle = 1;
  }
  const xStartPos = wheel.bodies[1].position.x - chainJointSize;
  const yStartPos = wheel.bodies[1].position.y - gearSize - chainJointSize;
  let currentCounterValForInterval = 0;
  let part = 1;
  let xPrev = xStartPos;
  let secondStartingX = 0;
  const chainStack = Composites.stack(
    xStartPos,
    yStartPos,
    nbrOfElm,
    1,
    4,
    0,
    function (x, y, counter) {
      if (xPrev < gearPosition.x - chainJointSize && part == 1) {
        x = xStartPos + (counter - currentCounterValForInterval) * xInterval;
        y = yStartPos - (counter - currentCounterValForInterval) * yInterval;
        if (
          xStartPos + (counter + 1) * xInterval >
          gearPosition.x - chainJointSize
        ) {
          part = 2;
          // y = gearPosition.y - gearSize - chainJointSize;
          currentCounterValForInterval = counter;
        }
      } else if (part == 2) {
        x =
          baseGearSize *
            Math.cos(0.425 * (counter - currentCounterValForInterval) - 1.65) +
          gear.bodies[0].position.x -
          chainJointSize;
        y =
          baseGearSize *
            Math.sin(0.425 * (counter - currentCounterValForInterval) - 1.65) +
          gear.bodies[0].position.y -
          chainJointSize;
        if (
          baseGearSize *
            Math.sin(
              0.425 * (counter - currentCounterValForInterval + 1) - 1.65
            ) +
            gear.bodies[0].position.y -
            chainJointSize <
            y &&
          x < gearPosition.x
        ) {
          part = 3;
          // y = gearPosition.y + baseGearSize - chainJointSize;
          currentCounterValForInterval = counter;
        }
      } else if (part == 3) {
        if (counter - currentCounterValForInterval == 1) {
          secondStartingX = x;
        }

        x =
          secondStartingX -
          (counter - currentCounterValForInterval) * xInterval;
        // y = gearPosition.y + gearSize - chainJointSize;
        y =
          gearPosition.y +
          baseGearSize -
          chainJointSize -
          (counter - currentCounterValForInterval) * yInterval;

        if (
          secondStartingX -
            (counter - currentCounterValForInterval + 1) * xInterval <
          backWheelPosition.x - chainJointSize
        ) {
          part = 4;
          currentCounterValForInterval = counter;
        }
      } else if (part == 4) {
        x =
          gearSize *
            Math.cos(
              angle * (counter - currentCounterValForInterval) + part4BaseAngle
            ) +
          wheel.bodies[1].position.x -
          chainJointSize;
        y =
          gearSize *
            Math.sin(
              angle * (counter - currentCounterValForInterval) + part4BaseAngle
            ) +
          wheel.bodies[1].position.y -
          chainJointSize;
      }
      xPrev = x;
      return Bodies.circle(x, y, chainJointSize, {
        id: chainStartingId + counter,
        label: "Chain Joint",
        isStatic: true,
        collisionFilter: {
          group: group,
        },
      });
    }
  );
  return chainStack;
};

const createStackForLargerSprocket = (
  gear,
  wheel,
  group,
  gearSize,
  baseGearSize
) => {
  let xInterval,
    yInterval,
    nbrOfElm,
    angle,
    part4BaseAngle = null;
  if (gearSize == 45) {
    xInterval = 12.51;
    yInterval = 1.361;
    nbrOfElm = 40;
    angle = 0.31;
    part4BaseAngle = 1.275;
  } else {
    xInterval = 12.51;
    yInterval = 0.65;
    nbrOfElm = 40;
    angle = 0.3;
    part4BaseAngle = 1.35;
  }
  const xStartPos = wheel.bodies[0].position.x + chainJointSize;
  const yStartPos = wheel.bodies[0].position.y - gearSize - chainJointSize;
  let currentCounterValForInterval = 0;
  let part = 1;
  let xPrev = xStartPos;
  let secondStartingX = 0;
  const chainStack = Composites.stack(
    xStartPos,
    yStartPos,
    nbrOfElm,
    1,
    4,
    0,
    function (x, y, counter) {
      if (xPrev < gearPosition.x - chainJointSize && part == 1) {
        x = xStartPos + (counter - currentCounterValForInterval) * xInterval;
        y = yStartPos + (counter - currentCounterValForInterval) * yInterval;
        if (
          xStartPos + (counter + 1) * xInterval >
          gearPosition.x - chainJointSize
        ) {
          part = 2;
          currentCounterValForInterval = counter;
        }
      } else if (part == 2) {
        x =
          baseGearSize *
            Math.cos(0.425 * (counter - currentCounterValForInterval) - 1.65) +
          gear.bodies[0].position.x -
          chainJointSize;
        y =
          baseGearSize *
            Math.sin(0.425 * (counter - currentCounterValForInterval) - 1.65) +
          gear.bodies[0].position.y -
          chainJointSize;
        if (
          baseGearSize *
            Math.sin(
              0.425 * (counter - currentCounterValForInterval + 1) - 1.65
            ) +
            gear.bodies[0].position.y -
            chainJointSize <
            y ||
          baseGearSize *
            Math.cos(
              0.425 * (counter - currentCounterValForInterval + 1) - 1.65
            ) +
            gear.bodies[0].position.x -
            chainJointSize <
            gearPosition.x
        ) {
          part = 3;
          currentCounterValForInterval = counter;
        }
      } else if (part == 3) {
        if (counter - currentCounterValForInterval == 1) {
          secondStartingX = xPrev;
        }
        x =
          secondStartingX -
          (counter - currentCounterValForInterval) * xInterval;
        y =
          gearPosition.y +
          baseGearSize -
          chainJointSize +
          (counter - currentCounterValForInterval) * yInterval;

        if (
          secondStartingX -
            (counter - currentCounterValForInterval + 1) * xInterval <
          backWheelPosition.x - chainJointSize
        ) {
          part = 4;
          currentCounterValForInterval = counter;
        }
      } else if (part == 4) {
        x =
          gearSize *
            Math.cos(
              angle * (counter - currentCounterValForInterval) + part4BaseAngle
            ) +
          wheel.bodies[1].position.x -
          chainJointSize;
        y =
          gearSize *
            Math.sin(
              angle * (counter - currentCounterValForInterval) + part4BaseAngle
            ) +
          wheel.bodies[1].position.y -
          chainJointSize;
      }
      xPrev = x;
      return Bodies.circle(x, y, chainJointSize, {
        id: chainStartingId + counter,
        label: "Chain Joint",
        isStatic: true,
        collisionFilter: {
          group: group,
        },
      });
    }
  );
  return chainStack;
};
