import { sprocketSizes } from "../engine/const";
import { createDistanceInput, createSpeedInput } from "./generalInputs";

export default function createGearInputs() {
  const inputContainer = document.createElement("div");
  inputContainer.setAttribute("class", "input-container");
  const slider = createSlider();
  const select = createSelect();
  const distance = createDistanceInput("sprocket-engine-");
  const revolution = createWheelRevolutionInput();
  const speed = createSpeedInput("sprocket-engine-");
  inputContainer.appendChild(select);
  inputContainer.appendChild(slider);
  inputContainer.appendChild(speed);
  inputContainer.appendChild(distance);
  inputContainer.appendChild(revolution);
  return inputContainer;
}

const createSelect = () => {
  const selectContainer = document.createElement("div");
  const select = document.createElement("select");
  const label = document.createElement("label");

  label.setAttribute("for", "sprocket-engine-select");
  label.setAttribute("class", "form-label");
  label.innerHTML = "Taille du pignon";

  select.setAttribute("class", "form-select form-control");
  select.setAttribute("id", "sprocket-engine-select");

  for (let i = 0; i < sprocketSizes.length; i++) {
    let option = document.createElement("option");
    option.setAttribute("value", i);
    option.innerHTML = sprocketSizes[i].name;
    if (i == 2) {
      option.setAttribute("selected", "");
    }
    select.appendChild(option);
  }

  selectContainer.appendChild(label);
  selectContainer.appendChild(select);

  return selectContainer;
};

const createSlider = () => {
  const rangeContainer = document.createElement("div");
  const label = document.createElement("label");
  const slider = document.createElement("input");
  const value = document.createElement("span");

  label.setAttribute("for", "sprocket-engine-spin-range");
  label.setAttribute("class", "form-label");
  label.innerHTML = "Tour de pédalier/min";

  slider.setAttribute("type", "range");
  slider.setAttribute("class", "form-range form-control");
  slider.setAttribute("id", "sprocket-engine-spin-range");
  slider.setAttribute("value", 0);
  slider.setAttribute("min", 0);
  slider.setAttribute("max", 120);
  slider.setAttribute("step", 1);

  value.setAttribute("class", "form-label");
  value.setAttribute("id", "sprocket-engine-spin-value");
  value.innerHTML = slider.value;

  rangeContainer.appendChild(label);
  rangeContainer.appendChild(slider);
  rangeContainer.appendChild(value);

  return rangeContainer;
};

const createWheelRevolutionInput = () => {
  const revolutionContainer = document.createElement("div");
  const revolutionPerMinuteContainer = document.createElement("div");
  const revolutionTotalContainer = document.createElement("div");

  let label = document.createElement("label");
  let input = document.createElement("input");

  label.setAttribute("for", "sprocket-engine-revolution");
  label.setAttribute("class", "form-label");
  label.innerHTML = "Tour de roues/min";

  input.setAttribute("class", "revolution form-control");
  input.setAttribute("id", "sprocket-engine-revolution");
  input.disabled = true;

  revolutionPerMinuteContainer.appendChild(label);
  revolutionPerMinuteContainer.appendChild(input);

  label = document.createElement("label");
  input = document.createElement("input");

  label.setAttribute("for", "sprocket-engine-revolution-total");
  label.setAttribute("class", "form-label");
  label.innerHTML = "Nbr de tour de roues total";

  input.setAttribute("class", "revolution form-control");
  input.setAttribute("id", "sprocket-engine-revolution-total");
  input.setAttribute("value", 0);
  input.disabled = true;

  revolutionTotalContainer.appendChild(label);
  revolutionTotalContainer.appendChild(input);

  revolutionContainer.appendChild(revolutionPerMinuteContainer);
  revolutionContainer.appendChild(revolutionTotalContainer);

  return revolutionContainer;
};
