import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles/styles.css";
import createInputs from "./inputs/wheelInput";
import createTopViewInputs from "./inputs/brakeInput";
import createDirectionInputs from "./inputs/directioninput";
import createGearInputs from "./inputs/gearInput";
import { createEngine, clearEngine } from "./engine/engine";
import createSelector from "./selector";
import { createSpeedometerCanvas } from "./inputs/generalInputs";

const clearActiveButtons = () => {
  buttons.forEach((button) => {
    button.setAttribute("class", "nav-link");
    button.setAttribute("aria-selected", "false");
  });
};

const updateSelectedValue = (button, container) => {
  button.addEventListener("click", (event) => {
    clearActiveButtons();
    button.setAttribute("class", "nav-link active disabled");
    button.setAttribute("aria-selected", "true");
    selectedValue = parseInt(event.target.id);
    clearEngine(container);
    updateView(selectedValue, container);
  });
};

const updateView = (selectedValue, container) => {
  let inputs = null;
  switch (selectedValue) {
    case 0:
      inputs = createInputs();
      break;
    case 1:
      inputs = createTopViewInputs();
      break;
    case 2:
      inputs = createDirectionInputs();
      break;
    case 3:
      inputs = createGearInputs();
      break;
    default:
      inputs = createInputs();
      break;
  }
  container.appendChild(inputs);
  let speedometer = document.getElementById("speedometer");
  if (speedometer !== undefined && speedometer !== null) {
    switch (selectedValue) {
      case 0:
        createSpeedometerCanvas("");
        break;
      case 1:
        createSpeedometerCanvas("brake-engine-");
        break;
      case 3:
        createSpeedometerCanvas("brake-engine-");
        break;
      default:
        createSpeedometerCanvas("");
        break;
    }
  }
  createEngine(container, selectedValue);
};

let selectedValue = 0;
let selector = createSelector();
document.body.appendChild(selector);
let buttons = selector.querySelectorAll("button");
let container = document.createElement("div");
document.body.appendChild(container);
container.setAttribute("class", "view");
buttons.forEach((button) => {
  updateSelectedValue(button, container);
});

updateView(selectedValue, container);
