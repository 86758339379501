import Matter from "matter-js";
import bikerImg from "../../assets/images/bikerNoHandles.png";
import handlesImg from "../../assets/images/Handles.png";
import { directionBikerSize, directionBikerScale } from "../const";

export default function createDirectionView() {
  const Composite = Matter.Composite,
    Bodies = Matter.Bodies,
    Body = Matter.Body,
    Constraint = Matter.Constraint;

  const group = Body.nextGroup(true);

  const bike = Composite.create({ label: "Bike", id: 0 }),
    biker = Bodies.rectangle(
      750,
      400,
      directionBikerSize.width,
      directionBikerSize.height,
      {
        label: "Moving Bike",
        isStatic: true,
        render: {
          sprite: {
            texture: bikerImg,
            xScale: directionBikerScale,
            yScale: directionBikerScale,
          },
        },
      }
    );

  const handles = Bodies.rectangle(750, 385, directionBikerSize.width, 3, {
    label: "Handles",
    isStatic: true,
    collisionFilter: {
      group: group,
    },
    render: {
      sprite: {
        texture: handlesImg,
        xScale: directionBikerScale,
        yScale: directionBikerScale,
      },
    },
  });

  const handleConstraint = Constraint.create({
    bodyA: biker,
    bodyB: handles,
    pointA: { x: 0, y: 0 },
    pointB: { x: 0, y: 0 },
    render: {
      visible: false,
    },
  });

  Composite.add(bike, [biker, handles, handleConstraint]);

  return bike;
}
