import Matter from "matter-js";

const kmhToMs = (val) => {
  return val * (5 / 18);
};

const msToKmh = (val) => {
  return val * 3.6;
};

const scrollBackground = (background, startingSpeed) => {
  Matter.Body.setPosition(background, {
    x: background.position.x,
    y:
      background.position.y + 1 * startingSpeed > 600
        ? 0
        : background.position.y + 1 * startingSpeed,
  });
};

const toRadians = (val) => {
  return val * (Math.PI / 180);
};

const isComposite = (elm) => {
  return elm.type == "composite";
};

const capitalizeFirstLetter = (string) => {
  return string[0].toUpperCase() + string.slice(1);
};

const getWheelCircumference = (currentWheelSize) => {
  return (currentWheelSize / 100) * Math.PI;
};

const getCurrentSpeed = (rotationSpeed, currentCircumference) => {
  return Math.round(currentCircumference * rotationSpeed * (1 / 6) * 10) / 10;
};

const getDistanceTraveled = (currentSpeed, counter) => {
  return Math.round(currentSpeed * (1000 / 36000) * counter * 10) / 10;
};

const rotateContinually = (
  elements,
  rotationSpeed,
  pedals,
  newX = null,
  newY = null,
  scale = null
) => {
  elements.forEach((elm) => {
    if (elm.type === "composite") {
      Matter.Composite.rotate(elm, rotationSpeed, {
        x: newX == null ? 390 : newX,
        y: newY == null ? 480 : newY,
      });
      pedals.forEach((pedal) => {
        Matter.Body.setAngle(pedal, 0);
      });
    } else {
      elm.isStatic = false;
      if (scale !== null && scale !== 0) {
        if (elm.label.includes("Wheel")) {
          Matter.Body.rotate(
            elm,
            scale === 1 ? rotationSpeed * 1.5 : rotationSpeed * 2
          );
        } else {
          Matter.Body.rotate(elm, rotationSpeed);
        }
      } else {
        Matter.Body.rotate(elm, rotationSpeed);
      }
    }
  });
};

export {
  kmhToMs,
  msToKmh,
  scrollBackground,
  isComposite,
  toRadians,
  capitalizeFirstLetter,
  getWheelCircumference,
  getCurrentSpeed,
  getDistanceTraveled,
  rotateContinually,
};
