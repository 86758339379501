export default function createDirectionInputs() {
  const inputContainer = document.createElement("div");
  inputContainer.setAttribute("class", "input-container");
  const spedSlider = createSpeedSlider();
  const angleSlider = createAngleSlider();
  inputContainer.appendChild(spedSlider);
  inputContainer.appendChild(angleSlider);
  return inputContainer;
}

const createSpeedSlider = () => {
  const rangeContainer = document.createElement("div");
  const label = document.createElement("label");
  const slider = document.createElement("input");
  const value = document.createElement("span");

  label.setAttribute("for", "direction-engine-speed-range");
  label.setAttribute("class", "form-label");
  label.innerHTML = "Vitesse";

  slider.setAttribute("type", "range");
  slider.setAttribute("class", "form-range form-control");
  slider.setAttribute("id", "direction-engine-speed-range");
  slider.setAttribute("value", 0);
  slider.setAttribute("min", -5);
  slider.setAttribute("max", +40);
  slider.setAttribute("step", 1);

  value.setAttribute("class", "form-label");
  value.setAttribute("id", "direction-engine-speed-value");
  value.innerHTML = slider.value;

  rangeContainer.appendChild(label);
  rangeContainer.appendChild(slider);
  rangeContainer.appendChild(value);

  return rangeContainer;
};

const createAngleSlider = () => {
  const rangeContainer = document.createElement("div");
  const label = document.createElement("label");
  const slider = document.createElement("input");
  const value = document.createElement("span");

  label.setAttribute("for", "direction-engine-direction-range");
  label.setAttribute("class", "form-label");
  label.innerHTML = "Angle du guidon";

  slider.setAttribute("type", "range");
  slider.setAttribute("class", "form-range form-control");
  slider.setAttribute("id", "direction-engine-direction-range");
  slider.setAttribute("value", 0);
  slider.setAttribute("min", -50);
  slider.setAttribute("max", 50);
  slider.setAttribute("step", 1);

  value.setAttribute("class", "form-label");
  value.setAttribute("id", "direction-engine-direction-value");
  value.innerHTML = slider.value;

  rangeContainer.appendChild(label);
  rangeContainer.appendChild(slider);
  rangeContainer.appendChild(value);

  return rangeContainer;
};
