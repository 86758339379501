import Matter from "matter-js";
import { directionBikerSize, engineSize } from "../const";

const init = (composite, runner) => {
  let currentSpeed = 0;
  let startingBikeAngle = 270;
  let currentHandleAngle = 0;
  let currentAngle = 0;
  let movingBike = null;
  let movingBikeComposite = null;
  let handles = null;
  let tick = 0;
  // timeCounter in 0.1s
  let timeCounter = 0;

  let bodies = Matter.Composite.allBodies(composite);
  let composites = Matter.Composite.allComposites(composite);
  for (let i = 0; i < bodies.length; i += 1) {
    let body = bodies[i];
    if (body.label === "Moving Bike") {
      movingBike = body;
    }
    if (body.label === "Handles") {
      handles = body;
    }
  }
  for (let i = 0; i < composites.length; i += 1) {
    let composite = composites[i];
    if (composite.label === "Bike") {
      movingBikeComposite = composite;
    }
  }

  let handleDirectionValue = document.getElementById(
    "direction-engine-direction-value"
  );
  let handleDirectionRange = document.getElementById(
    "direction-engine-direction-range"
  );
  handleDirectionRange.addEventListener("input", () => {
    handleDirectionValue.innerHTML = handleDirectionRange.value;
    currentHandleAngle = parseInt(handleDirectionRange.value);
    Matter.Body.setAngle(
      handles,
      movingBike.angle + (currentHandleAngle / 1.25) * ((Math.PI * 1) / 180)
    );
  });

  let speedValue = document.getElementById("direction-engine-speed-value");
  let speedRange = document.getElementById("direction-engine-speed-range");
  speedRange.addEventListener("input", () => {
    speedValue.innerHTML = speedRange.value;
    currentSpeed = parseInt(speedRange.value);
  });

  Matter.Events.on(runner, "afterTick", function () {
    if (timeCounter === 0) {
      currentAngle = startingBikeAngle + currentHandleAngle / 2;
    }
    tick += 1;
    if (currentSpeed !== 0) {
      let xx =
        movingBike.position.x +
        1 * (currentSpeed / 10) * Math.cos(currentAngle * (Math.PI / 180));

      let yy =
        movingBike.position.y +
        1 * (currentSpeed / 10) * Math.sin(currentAngle * (Math.PI / 180));

      let nextPos = {
        x: xx,
        y: yy,
      };
      if (
        nextPos.y > directionBikerSize.height / 2 &&
        nextPos.y < engineSize.height - directionBikerSize.height / 2 &&
        nextPos.x > directionBikerSize.height / 2 &&
        nextPos.x < engineSize.width - directionBikerSize.height / 2
      ) {
        Matter.Composite.translate(movingBikeComposite, {
          x: xx - movingBike.position.x,
          y: yy - movingBike.position.y,
        });
      }
      if (tick >= 6) {
        // reset tick after every 0.1sec, and add 1 to counter for distance
        tick = 0;
        timeCounter += 1;
        currentAngle = currentAngle + currentHandleAngle / 2;
        Matter.Composite.rotate(
          movingBikeComposite,
          (currentAngle - 270) * ((Math.PI * 1) / 180) - movingBike.angle,
          {
            x: movingBike.position.x,
            y: movingBike.position.y,
          }
        );
      }
    }
  });
};

export { init };
