import Matter from "matter-js";
import createBike from "./wheelEngine/wheelView";
import createBrakeView from "./brakeEngine/brakeView";
import createDirectionView from "./directionEngine/directionView";
import roadImg from "../assets/images/road.png";
import createGearBike from "./gearEngine/gearView";

const groundY = 610;
const groundHeight = 60;

export default function createWorld(engine, render, selectedValue) {
  const Bodies = Matter.Bodies,
    Composite = Matter.Composite;

  const gridBackground = Bodies.rectangle(500, 300, 1, 1, {
    label: "Background",
    isStatic: true,
    isSensor: true,
    render: {
      sprite: {
        texture: roadImg,
      },
    },
  });

  const ground = Bodies.rectangle(500, groundY, 1000, groundHeight, {
    isStatic: true,
  });

  if (selectedValue === 0) {
    const bike = createBike();
    Composite.add(engine.world, [ground, bike]);
    return bike;
  } else if (selectedValue === 1) {
    const brakeView = createBrakeView();
    Composite.add(engine.world, [brakeView, gridBackground]);
    return engine.world;
  } else if (selectedValue === 2) {
    const directionView = createDirectionView();
    Composite.add(engine.world, [directionView, gridBackground]);

    return engine.world;
  } else if (selectedValue === 3) {
    const bike = createGearBike();
    Composite.add(engine.world, [ground, bike]);

    return engine.world;
  } else {
    const bike = createBike();
    Composite.add(engine.world, [ground, bike]);
    return bike;
  }
}
