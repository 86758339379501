import { engineSize } from "./const";

export default function createRender(engine, Render, view) {
  const render = Render.create({
    element: view,
    engine: engine,
    id: "engine-canvas",
    options: {
      width: engineSize.width,
      height: engineSize.height,
      id: "engine-canvas",
      wireframes: false, // disable Wireframe
      // showAxes: true,
      // showCollisions: true,
      // showConvexHulls: true,
    },
  });

  return render;
}
