import { toRadians } from "./utils";

const engineSize = { width: 1000, height: 600 };

const wheelIds = [2, 3];

const gearIds = [4, 5];

const chainStartingId = 6;

const idsToRotate = [...wheelIds, ...gearIds];

const wheelSizes = [
  { name: '20"', size: 60, val: 50.8 },
  { name: '24"', size: 80, val: 60.96 },
  { name: '27.5"', size: 100, val: 69.85 },
  { name: '29"', size: 110, val: 73.66 },
  { name: '32"', size: 130, val: 81.28 },
];

const sprocketSizes = [
  { name: "2x plus petit", size: 15, val: 12 },
  { name: "1.5x plus petit", size: 22.5, val: 18 },
  { name: "Même taille", size: 30, val: 21 },
  { name: "1.5x plus grand", size: 37.5, val: 24 },
  { name: "2x plus grand", size: 45, val: 30 },
];

const frontWheelPosition = { x: 550, y: 480 };
const backWheelPosition = { x: 250, y: 480 };

const gearPosition = { x: 390, y: 480 };
const gearSize = 30;

const chainJointSize = 5;

const directionBikerSize = {
  width: 30.75,
  height: 72.75,
};
const directionBikerScale = 0.25;

const gaugeOpts = {
  angle: toRadians(-15), // The span of the gauge arc
  lineWidth: 0.1, // The line thickness
  radiusScale: 0.75, // Relative radius
  pointer: {
    length: 0.6, // // Relative to gauge radius
    strokeWidth: 0.035, // The thickness
    color: "#000000", // Fill color
  },
  animationSpeed: 1,
  limitMax: false, // If false, max value increases automatically if value > maxValue
  limitMin: false, // If true, the min value of the gauge will be fixed
  colorStart: "#6FADCF", // Colors
  colorStop: "#8FC0DA", // just experiment with them
  strokeColor: "#E0E0E0", // to see which ones work best for you
  generateGradient: true,
  highDpiSupport: true, // High resolution support
  staticZones: [
    { strokeStyle: "#30B32D", min: 0, max: 20 }, // Green
    { strokeStyle: "#FFDD00", min: 20, max: 40 }, // Yellow
    { strokeStyle: "#F03E3E", min: 40, max: 60 }, // Red
  ],
  staticLabels: {
    font: "10px sans-serif", // Specifies font
    labels: [0, 10, 20, 30, 40, 50, 60], // Print labels at these values
    color: "#000000", // Optional: Label text color
    fractionDigits: 0, // Optional: Numerical precision. 0=round off.
    firstLastHorizontal: true,
  },
  renderTicks: {
    divisions: 12,
    divWidth: 1.1,
    divLength: 0.7,
    divColor: "#333333",
    subDivisions: 5,
    subLength: 0.5,
    subWidth: 0.6,
    subColor: "#666666",
  },
};

const gaugeOptsMs = {
  angle: toRadians(-15), // The span of the gauge arc
  lineWidth: 0.1, // The line thickness
  radiusScale: 0.75, // Relative radius
  pointer: {
    length: 0.6, // // Relative to gauge radius
    strokeWidth: 0.035, // The thickness
    color: "#000000", // Fill color
  },
  animationSpeed: 1,
  limitMax: false, // If false, max value increases automatically if value > maxValue
  limitMin: false, // If true, the min value of the gauge will be fixed
  colorStart: "#6FADCF", // Colors
  colorStop: "#8FC0DA", // just experiment with them
  strokeColor: "#E0E0E0", // to see which ones work best for you
  generateGradient: true,
  highDpiSupport: true, // High resolution support
  staticZones: [
    { strokeStyle: "#30B32D", min: 0, max: 6 }, // Green
    { strokeStyle: "#FFDD00", min: 6, max: 12 }, // Yellow
    { strokeStyle: "#F03E3E", min: 12, max: 18 }, // Red
  ],
  staticLabels: {
    font: "10px sans-serif", // Specifies font
    labels: [0, 3, 6, 9, 12, 15, 18], // Print labels at these values
    color: "#000000", // Optional: Label text color
    fractionDigits: 0, // Optional: Numerical precision. 0=round off.
    firstLastHorizontal: true,
  },
  renderTicks: {
    divisions: 12,
    divWidth: 1.1,
    divLength: 0.7,
    divColor: "#333333",
    subDivisions: 5,
    subLength: 0.5,
    subWidth: 0.6,
    subColor: "#666666",
  },
};

const kmGaugeVal = { min: 0, max: 60 };
const mGaugeVal = { min: 0, max: 18 };

export {
  engineSize,
  wheelIds,
  gearIds,
  wheelSizes,
  sprocketSizes,
  frontWheelPosition,
  backWheelPosition,
  idsToRotate,
  chainStartingId,
  gearPosition,
  gearSize,
  chainJointSize,
  directionBikerSize,
  directionBikerScale,
  gaugeOpts,
  gaugeOptsMs,
  kmGaugeVal,
  mGaugeVal,
};
