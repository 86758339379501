import Matter from "matter-js";
import { Gauge } from "gaugeJS";
import {
  idsToRotate,
  gearPosition,
  backWheelPosition,
  gearSize,
  sprocketSizes,
  gaugeOpts,
  gaugeOptsMs,
  wheelSizes,
} from "../const.js";
import createChain from "../wheelEngine/chain.js";
import { initChain as initRegularChain } from "../wheelEngine/wheelUtils";
import {
  getCurrentSpeed,
  getDistanceTraveled,
  getWheelCircumference,
  toRadians,
  kmhToMs,
  rotateContinually,
} from "../utils";
const Body = Matter.Body;
let circleBodies = [];
let chainBodies = [];
let chainComposite = null;
let rotationSpeed = 0;
let currentSprocketSize = sprocketSizes[0].size;
let counterForDistance = 0;
let tick = 0;
let markers = [];
let wheelAndGearConstraints = [];
let currentScaleLvl = 2;
let currentGearPos = { x: gearPosition.x, y: gearPosition.y };
let currentBackWheelPos = { x: backWheelPosition.x, y: backWheelPosition.y };
let currentGearSize = gearSize;
let cranckset = null;
let pedals = [];
let crancksetAndPedalsConstraints = [];
let sprocket = null;
let bikeComposite = null;
let wheelComposite = null;
let gearComposite = null;
let newChain = null;
let currentSpeed = 0;
let currentSpeedUnit = 0;
let crancksetComposite = null;
let wheelRevolution = 0;
let gauge = null;
const wheelCircumference = getWheelCircumference(wheelSizes[2].val);
const degree = 0.4;
const radians = toRadians(degree);

const initGears = (composite, engine, runner) => {
  let bodies = Matter.Composite.allBodies(composite);
  let composites = Matter.Composite.allComposites(composite);
  let constraints = Matter.Composite.allConstraints(composite);
  for (let i = 0; i < bodies.length; i += 1) {
    let body = bodies[i];
    if (idsToRotate.includes(body.id)) {
      circleBodies.push(body);
    }
    if (body.label === "Wheel Gear") {
      sprocket = body;
    }
    if (body.label === "Chain Joint") {
      chainBodies.push(body);
    }
    if (body.label === "Wheel Markers") {
      markers.push(body);
    }
    if (body.label === "Cranckset") {
      cranckset = body;
    }
    if (body.label.includes("Pedal")) {
      pedals.push(body);
    }
  }
  for (let i = 0; i < composites.length; i += 1) {
    let composite = composites[i];
    if (composite.label === "Bike") {
      bikeComposite = composite;
    }
    if (composite.label === "Chain Composite") {
      chainComposite = composite;
    }
    if (composite.label === "Gear Composite") {
      gearComposite = composite;
    }
    if (composite.label === "Back Wheel Composite") {
      wheelComposite = composite;
    }
    if (composite.label === "Cranckset Composite") {
      crancksetComposite = composite;
    }
  }
  for (let i = 0; i < constraints.length; i += 1) {
    let constraint = constraints[i];
    if (
      constraint.label === "Wheel Constraint" ||
      constraint.label === "Gear Constraint"
    ) {
      wheelAndGearConstraints.push(constraint);
    }
    if (
      constraint.label === "Cranckset Constraint" ||
      constraint.label.includes("Pedal Constraint")
    ) {
      crancksetAndPedalsConstraints.push(constraint);
    }
  }

  let rotationValue = document.getElementById("sprocket-engine-spin-value");
  let rotationRange = document.getElementById("sprocket-engine-spin-range");
  let speedMeter = document.getElementById("sprocket-engine-speedometer");
  let speedMeterLabel = document.getElementById(
    "sprocket-engine-speedometer-label"
  );
  let mdistanceMeter = document.getElementById("sprocket-engine-m-distance");
  let kmdistanceMeter = document.getElementById("sprocket-engine-km-distance");
  let wheelTurnMeter = document.getElementById("sprocket-engine-revolution");
  let wheelRevolutions = document.getElementById(
    "sprocket-engine-revolution-total"
  );

  let sprocketSelect = document.getElementById("sprocket-engine-select");
  sprocketSelect.addEventListener("input", () => {
    currentSprocketSize = sprocketSizes[sprocketSelect.value].size;
    updateSprocketSize(sprocket, sprocketSizes[sprocketSelect.value].size);
    currentScaleLvl = sprocketSelect.value;
    if (newChain === null) {
      Matter.Composite.remove(bikeComposite, chainComposite);
    } else {
      Matter.Composite.remove(bikeComposite, newChain);
    }
    newChain = createChain(
      gearComposite,
      wheelComposite,
      wheelComposite.bodies[0].collisionFilter.group,
      sprocketSizes[sprocketSelect.value].size,
      30
    );
    Matter.Composite.add(bikeComposite, newChain);

    if (speedMeter !== null && speedMeter !== undefined) {
      updateSpeedoMeter(speedMeter);
    }
  });

  if (speedMeter !== null && speedMeter !== undefined) {
    updateSpeedoMeter(speedMeter);
  }

  let unitSelect = document.getElementById("sprocket-engine-unit-select");
  unitSelect.addEventListener("input", () => {
    currentSpeedUnit = parseInt(unitSelect.value);
    updateSpeedoMeter(speedMeter);
  });

  Matter.Events.on(runner, "afterTick", function () {
    tick += 1;

    if (rotationRange !== null && rotationRange !== undefined) {
      rotationRange.addEventListener("input", () => {
        rotationValue.innerHTML = rotationRange.value;
        rotationSpeed = rotationRange.value;
      });
    }

    if (wheelTurnMeter !== null && wheelTurnMeter !== undefined) {
      if (sprocketSelect.value == 0) {
        wheelTurnMeter.value = rotationSpeed * 2;
      } else if (sprocketSelect.value == 1) {
        wheelTurnMeter.value = rotationSpeed * 1.5;
      } else if (sprocketSelect.value == 2) {
        wheelTurnMeter.value = rotationSpeed;
      } else if (sprocketSelect.value == 3) {
        wheelTurnMeter.value = rotationSpeed / 1.5;
      } else if (sprocketSelect.value == 4) {
        wheelTurnMeter.value = rotationSpeed / 2;
      }
      wheelRevolution = wheelTurnMeter.value;
    }

    if (speedMeter !== null && speedMeter !== undefined) {
      currentSpeed =
        currentSpeedUnit === 0
          ? getCurrentSpeed(wheelRevolution, wheelCircumference)
          : kmhToMs(getCurrentSpeed(wheelRevolution, wheelCircumference));
      gauge.set(currentSpeed);
      speedMeterLabel.innerHTML =
        currentSpeedUnit === 0 ? currentSpeed + "Km/h" : currentSpeed + "m/s";
      speedMeterLabel.setAttribute("class", "speedometer-label");
    }

    if (mdistanceMeter !== null && mdistanceMeter !== undefined) {
      mdistanceMeter.value = getDistanceTraveled(
        currentSpeed,
        counterForDistance
      );
    }

    if (kmdistanceMeter !== null && kmdistanceMeter !== undefined) {
      kmdistanceMeter.value =
        getDistanceTraveled(currentSpeed, counterForDistance) / 1000;
    }

    if (parseInt(rotationSpeed) !== 0) {
      if (tick >= 6) {
        // reset tick after every 0.1sec, and add 1 to counter for distance
        tick = 0;
        counterForDistance += 1;
      }
      rotateContinually(
        [...circleBodies, crancksetComposite],
        rotationSpeed / 3 / 100,
        pedals,
        null,
        null,
        currentScaleLvl
      );
      if (wheelRevolutions !== null && wheelRevolutions !== undefined) {
        wheelRevolutions.value =
          wheelRevolution * ((1 / 600) * counterForDistance);
      }
      if (currentScaleLvl == 0 || currentScaleLvl == 1) {
        initSmallerChain(
          newChain == null ? chainBodies : Matter.Composite.allBodies(newChain),
          rotationSpeed / 3
        );
      } else if (currentScaleLvl == 2) {
        initRegularChain(
          newChain == null ? chainBodies : Matter.Composite.allBodies(newChain),
          rotationSpeed / 3
        );
      } else if (currentScaleLvl == 3 || currentScaleLvl == 4) {
        initLargerChain(
          newChain == null ? chainBodies : Matter.Composite.allBodies(newChain),
          rotationSpeed / 3
        );
      }
    } else {
      circleBodies.forEach((circle) => {
        circle.isStatic = true;
      });
      cranckset.isStatic = true;
      tick = 0;
      counterForDistance = 0;
      wheelRevolutions.value = 0;
    }
  });
};

const initLargerChain = (chainBodies, rotationSpeed) => {
  // let tresholdValue = getTresholdValueForChain();
  let yTranslationAngle = null;
  let xTranslation = null;
  let smallDegree = null;
  if (currentScaleLvl == 3) {
    xTranslation = 0.2;
    yTranslationAngle = 0.01;
    smallDegree = toRadians(0.3);
  } else {
    xTranslation = 0.2;
    yTranslationAngle = 0.02;
    smallDegree = toRadians(0.5);
  }

  chainBodies.forEach((elm) => {
    if (
      elm.position.x <= currentGearPos.x &&
      elm.position.x >= backWheelPosition.x &&
      elm.position.y <= currentGearPos.y - currentGearSize &&
      elm.position.y >= backWheelPosition.y - currentSprocketSize
    ) {
      if (
        elm.position.x + rotationSpeed * xTranslation >= 390 ||
        elm.position.y + rotationSpeed * yTranslationAngle >= 450
      ) {
        Body.setPosition(elm, {
          x: 390.01,
          y: 450,
        });
      } else {
        Body.setPosition(elm, {
          x: elm.position.x + rotationSpeed * xTranslation,
          y: elm.position.y + rotationSpeed * yTranslationAngle,
        });
      }
    } else if (
      elm.position.x <= currentGearPos.x &&
      elm.position.x >= backWheelPosition.x &&
      elm.position.y <= currentBackWheelPos.y + currentSprocketSize &&
      elm.position.y >= currentGearPos.y + currentGearSize
    ) {
      if (
        elm.position.x - rotationSpeed * xTranslation <= 250 ||
        elm.position.y + rotationSpeed * yTranslationAngle >=
          480 + currentSprocketSize
      ) {
        Body.setPosition(elm, {
          x: 249.99,
          y: 480 + currentSprocketSize,
        });
      } else {
        Body.setPosition(elm, {
          x: elm.position.x - rotationSpeed * xTranslation,
          y: elm.position.y + rotationSpeed * yTranslationAngle,
        });
      }
    } else if (
      elm.position.x > currentGearPos.x &&
      elm.position.y <= currentGearPos.y + currentGearSize
    ) {
      let x =
        Math.cos(radians * rotationSpeed) *
          (elm.position.x - currentGearPos.x) -
        Math.sin(radians * rotationSpeed) *
          (elm.position.y - currentGearPos.y) +
        currentGearPos.x;
      let y =
        Math.sin(radians * rotationSpeed) *
          (elm.position.x - currentGearPos.x) +
        Math.cos(radians * rotationSpeed) *
          (elm.position.y - currentGearPos.y) +
        currentGearPos.y;
      if (Math.abs(510 - y) < 0.25) {
        Body.setPosition(elm, {
          x: 390,
          y: 510,
        });
      } else {
        Body.setPosition(elm, {
          x: x,
          y: y,
        });
      }
    } else if (
      elm.position.x < currentBackWheelPos.x &&
      elm.position.y >= currentBackWheelPos.y - currentSprocketSize
    ) {
      let x =
        Math.cos(smallDegree * rotationSpeed) *
          (elm.position.x - currentBackWheelPos.x) -
        Math.sin(smallDegree * rotationSpeed) *
          (elm.position.y - currentBackWheelPos.y) +
        currentBackWheelPos.x;
      let y =
        Math.sin(smallDegree * rotationSpeed) *
          (elm.position.x - currentBackWheelPos.x) +
        Math.cos(smallDegree * rotationSpeed) *
          (elm.position.y - currentBackWheelPos.y) +
        currentBackWheelPos.y;
      if (Math.abs(480 - currentSprocketSize - y) < 0.25) {
        Body.setPosition(elm, {
          x: 250,
          y: 480 - currentSprocketSize,
        });
      } else {
        Body.setPosition(elm, {
          x: x,
          y: y,
        });
      }
    } else {
      Body.setPosition(elm, {
        x: elm.position.x,
        y: 510.1,
      });
    }
  });
};

const initSmallerChain = (chainBodies, rotationSpeed) => {
  // let tresholdValue = getTresholdValueForChain();
  let yTranslationAngle = null;
  let xTranslation = null;
  let smallDegree = null;
  if (currentScaleLvl == 1) {
    xTranslation = 0.22;
    yTranslationAngle = 0.0103;
    smallDegree = toRadians(0.55);
  } else {
    xTranslation = 0.2;
    yTranslationAngle = 0.02;
    smallDegree = toRadians(0.5);
  }

  chainBodies.forEach((elm) => {
    if (
      elm.position.x <= currentGearPos.x &&
      elm.position.x >= backWheelPosition.x &&
      elm.position.y >= currentGearPos.y - currentGearSize &&
      elm.position.y <= backWheelPosition.y - currentSprocketSize
    ) {
      if (
        elm.position.x + rotationSpeed * xTranslation >= 390 ||
        elm.position.y - rotationSpeed * yTranslationAngle <= 450
      ) {
        Body.setPosition(elm, {
          x: 390.01,
          y: 450,
        });
      } else {
        Body.setPosition(elm, {
          x: elm.position.x + rotationSpeed * xTranslation,
          y: elm.position.y - rotationSpeed * yTranslationAngle,
        });
      }
    } else if (
      elm.position.x <= currentGearPos.x &&
      elm.position.x >= backWheelPosition.x &&
      elm.position.y >= currentBackWheelPos.y + currentSprocketSize &&
      elm.position.y <= currentGearPos.y + currentGearSize
    ) {
      if (
        elm.position.x - rotationSpeed * xTranslation <= 250 ||
        elm.position.y - rotationSpeed * yTranslationAngle <=
          480 + currentSprocketSize
      ) {
        Body.setPosition(elm, {
          x: 249.99,
          y: 480 + currentSprocketSize,
        });
      } else {
        Body.setPosition(elm, {
          x: elm.position.x - rotationSpeed * xTranslation,
          y: elm.position.y - rotationSpeed * yTranslationAngle,
        });
      }
    } else if (
      elm.position.x > currentGearPos.x &&
      elm.position.y <= currentGearPos.y + currentGearSize
    ) {
      let x =
        Math.cos(radians * rotationSpeed) *
          (elm.position.x - currentGearPos.x) -
        Math.sin(radians * rotationSpeed) *
          (elm.position.y - currentGearPos.y) +
        currentGearPos.x;
      let y =
        Math.sin(radians * rotationSpeed) *
          (elm.position.x - currentGearPos.x) +
        Math.cos(radians * rotationSpeed) *
          (elm.position.y - currentGearPos.y) +
        currentGearPos.y;
      if (Math.abs(510 - y) < 0.25) {
        Body.setPosition(elm, {
          x: 390,
          y: 510,
        });
      } else {
        Body.setPosition(elm, {
          x: x,
          y: y,
        });
      }
    } else if (
      elm.position.x < currentBackWheelPos.x &&
      elm.position.y >= currentBackWheelPos.y - currentSprocketSize
    ) {
      let x =
        Math.cos(smallDegree * rotationSpeed) *
          (elm.position.x - currentBackWheelPos.x) -
        Math.sin(smallDegree * rotationSpeed) *
          (elm.position.y - currentBackWheelPos.y) +
        currentBackWheelPos.x;
      let y =
        Math.sin(smallDegree * rotationSpeed) *
          (elm.position.x - currentBackWheelPos.x) +
        Math.cos(smallDegree * rotationSpeed) *
          (elm.position.y - currentBackWheelPos.y) +
        currentBackWheelPos.y;
      if (Math.abs(480 - currentSprocketSize - y) < 0.25) {
        Body.setPosition(elm, {
          x: 250,
          y: 480 - currentSprocketSize,
        });
      } else {
        Body.setPosition(elm, {
          x: x,
          y: y,
        });
      }
    } else {
      Body.setPosition(elm, {
        x: 250,
        y: currentBackWheelPos.y - currentSprocketSize,
      });
    }
  });
};

const updateSprocketSize = (sprocket, size) => {
  sprocket.render.sprite.xScale = 0.5;
  sprocket.render.sprite.yScale = 0.5;
  currentSprocketSize = 30;
  sprocket.render.sprite.xScale = 0.5 * (size / 30);
  sprocket.render.sprite.yScale = 0.5 * (size / 30);
  currentSprocketSize = sprocket.circleRadius * (size / 30);
};

const updateSpeedoMeter = (speedMeter) => {
  gauge = new Gauge(speedMeter);
  let options = currentSpeedUnit === 0 ? gaugeOpts : gaugeOptsMs;
  if (currentScaleLvl == 0) {
    gauge.maxValue = currentSpeedUnit === 0 ? 100 : 27;
    options.staticZones =
      currentSpeedUnit === 0
        ? [
            { strokeStyle: "#30B32D", min: 0, max: 20 },
            { strokeStyle: "#FFDD00", min: 20, max: 40 },
            { strokeStyle: "#F03E3E", min: 40, max: 100 },
          ]
        : [
            { strokeStyle: "#30B32D", min: 0, max: 6 },
            { strokeStyle: "#FFDD00", min: 6, max: 12 },
            { strokeStyle: "#F03E3E", min: 12, max: 27 },
          ];
    options.staticLabels.labels =
      currentSpeedUnit === 0
        ? [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
        : [0, 3, 6, 9, 12, 15, 18, 21, 24, 27];
  } else if (currentScaleLvl == 1) {
    gauge.maxValue = currentSpeedUnit === 0 ? 80 : 24;
    options.staticZones =
      currentSpeedUnit === 0
        ? [
            { strokeStyle: "#30B32D", min: 0, max: 20 },
            { strokeStyle: "#FFDD00", min: 20, max: 40 },
            { strokeStyle: "#F03E3E", min: 40, max: 80 },
          ]
        : [
            { strokeStyle: "#30B32D", min: 0, max: 6 },
            { strokeStyle: "#FFDD00", min: 6, max: 12 },
            { strokeStyle: "#F03E3E", min: 12, max: 24 },
          ];
    options.staticLabels.labels =
      currentSpeedUnit === 0
        ? [0, 10, 20, 30, 40, 50, 60, 70, 80]
        : [0, 3, 6, 9, 12, 15, 18, 21, 24];
  } else {
    gauge.maxValue = currentSpeedUnit === 0 ? 60 : 18;
    options.staticZones =
      currentSpeedUnit === 0
        ? [
            { strokeStyle: "#30B32D", min: 0, max: 20 },
            { strokeStyle: "#FFDD00", min: 20, max: 40 },
            { strokeStyle: "#F03E3E", min: 40, max: 60 },
          ]
        : [
            { strokeStyle: "#30B32D", min: 0, max: 6 },
            { strokeStyle: "#FFDD00", min: 6, max: 12 },
            { strokeStyle: "#F03E3E", min: 12, max: 18 },
          ];
    options.staticLabels.labels =
      currentSpeedUnit === 0
        ? [0, 10, 20, 30, 40, 50, 60]
        : [0, 3, 6, 9, 12, 15, 18];
  }
  options.renderTicks.divisions = (options.staticLabels.labels.length - 1) * 2;
  gauge.setOptions(options);
  gauge.setMinValue(0);
};

export { initGears };
