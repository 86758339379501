import Matter from "matter-js";
import createGear from "./gear";
import createWheel from "./wheel";
import createChain from "./chain";
import frameImg from "../../assets/images/frame2.png";

export default function createBike() {
  const Composite = Matter.Composite;

  const group = Matter.Body.nextGroup(true);

  const bike = Composite.create({ label: "Bike", id: 0 }),
    frame = Matter.Bodies.rectangle(415, 380, 100, 100, {
      id: 1,
      label: "Bike Frame",
      isStatic: true,
      collisionFilter: {
        group: group,
      },
      render: {
        sprite: {
          texture: frameImg,
          xScale: 0.5,
          yScale: 0.5,
        },
      },
    });

  const frontWheel = createWheel(true, group);
  const wheel = createWheel(false, group);
  const gear = createGear(group);
  const chain = createChain(gear, wheel, group, 30, 30);

  Composite.add(bike, [frame, wheel, frontWheel, chain, gear]);

  return bike;
}
