import { capitalizeFirstLetter } from "./engine/utils";

export default function createSelector() {
  let container = document.createElement("ul");
  container.setAttribute("class", "nav nav-tabs nav-fill my-3");
  container.setAttribute("id", "tabContainer");
  container.setAttribute("role", "tabList");
  container.appendChild(createButton("Roues", 0));
  container.appendChild(createButton("Freins", 1));
  container.appendChild(createButton("Direction", 2));
  container.appendChild(createButton("Embrayage", 3));

  return container;
}

const createButton = (label, value) => {
  let item = document.createElement("li");
  let button = document.createElement("button");
  item.setAttribute("class", "nav-item");
  if (value == 0) {
    button.setAttribute("class", "nav-link active disabled");
    button.setAttribute("aria-selected", "true");
  } else {
    button.setAttribute("class", "nav-link");
    button.setAttribute("aria-selected", "false");
  }

  item.setAttribute("role", "tab" + capitalizeFirstLetter(label));

  button.setAttribute("id", value);
  button.setAttribute("data-bs-toggle", "tab");
  button.setAttribute("data-bs-target", "#" + value);
  button.setAttribute("type", button);
  button.setAttribute("role", "tab");
  button.setAttribute("aria-controls", value);

  button.innerHTML = label;
  item.appendChild(button);
  return item;
};
