import Matter from "matter-js";
import createRender from "./render";
import init from "./brakeEngine/brakeUtils";
import { init as initDirection } from "./directionEngine/directionUtils";
import { initBike as initWheels } from "./wheelEngine/wheelUtils";
import { initGears } from "./gearEngine/gearUtils";
import createWorld from "./world";

let engine = null;
let render = null;
let bike = null;
let runner = null;
// module aliases
var Engine = Matter.Engine,
  Render = Matter.Render,
  Runner = Matter.Runner;

const createEngine = (container, selectedValue) => {
  // create an engine
  engine = Engine.create();

  // create a renderer
  render = createRender(engine, Render, container);

  // create world and its elements, and return main element of our world
  bike = createWorld(engine, render, selectedValue);

  // run the renderer
  Render.run(render);

  // create runner
  runner = Runner.create();

  // init our bike
  switch (selectedValue) {
    case 0:
      initWheels(bike, runner);
      break;
    case 1:
      init(bike, runner);
      break;
    case 2:
      initDirection(bike, runner);
      break;
    case 3:
      initGears(bike, engine, runner);
      break;
    default:
      initWheels(bike, runner);
      break;
  }

  // run the engine
  Runner.run(runner, engine);
};

const clearEngine = (container) => {
  engine.events = {};
  Runner.stop(engine);
  Engine.clear(engine);
  while (container.firstChild) {
    container.firstChild.remove();
  }
};

export { createEngine, clearEngine };
