import Matter from "matter-js";
import {
  frontWheelPosition,
  backWheelPosition,
  wheelSizes,
  wheelIds,
  gearIds,
} from "../const";
import wheelImg from "../../assets/images/wheel.png";
import gearImg from "../../assets/images/gear.png";

export default function createWheel(isFront, group) {
  const Bodies = Matter.Bodies,
    Composite = Matter.Composite,
    Constraint = Matter.Constraint;

  const options = {
    render: {
      sprite: {
        texture: wheelImg,
      },
    },
    collisionFilter: {
      group: group,
    },
    friction: 0,
  };

  let wheel,
    body = null;
  if (isFront) {
    (wheel = Composite.create({ label: "Wheel" })),
      (body = Bodies.circle(
        frontWheelPosition.x,
        frontWheelPosition.y,
        wheelSizes[2].size,
        options
      ));
    body.id = wheelIds[0];
    body.label = "Front Wheel";
    let marker = Bodies.circle(
      frontWheelPosition.x,
      frontWheelPosition.y - wheelSizes[2].size + 4,
      5,
      {
        id: 1000,
        label: "Wheel Markers",
        collisionFilter: {
          group: group,
        },
        friction: 0,
        render: {
          fillStyle: "#FF0000",
        },
      }
    );
    let markerConstraint = Constraint.create({
      bodyA: marker,
      bodyB: body,
      pointB: { x: 0, y: -wheelSizes[2].size + 4 },
      stiffness: 1,
      length: 0,
      render: {
        visible: false,
      },
      label: "Marker Constraint",
    });

    let wheelConstraint = Constraint.create({
      bodyA: body,
      pointB: { x: frontWheelPosition.x, y: frontWheelPosition.y },
      stiffness: 1,
      length: 0,
      label: "Wheel Constraint",
    });
    Composite.add(wheel, [body, marker, markerConstraint, wheelConstraint]);
  } else {
    (wheel = Composite.create({ label: "Back Wheel Composite" })),
      (body = Bodies.circle(
        backWheelPosition.x,
        backWheelPosition.y,
        wheelSizes[2].size,
        options
      ));
    body.id = wheelIds[1];
    body.label = "Back Wheel";

    const gear = Bodies.circle(backWheelPosition.x, backWheelPosition.y, 30, {
      label: "Wheel Gear",
      id: gearIds[0],
      collisionFilter: {
        group: group,
      },
      render: {
        sprite: {
          texture: gearImg,
          xScale: 0.5,
          yScale: 0.5,
        },
      },
    });
    let marker = Bodies.circle(
      backWheelPosition.x,
      backWheelPosition.y - wheelSizes[2].size + 4,
      5,
      {
        id: 1001,
        label: "Wheel Markers",
        collisionFilter: {
          group: group,
        },
        friction: 0,
        render: {
          fillStyle: "#FF0000",
        },
      }
    );

    let axisConstraint = Constraint.create({
      bodyA: gear,
      bodyB: body,
      stiffness: 1,
      length: 0,
    });

    let markerConstraint = Constraint.create({
      bodyA: marker,
      bodyB: body,
      pointB: { x: 0, y: -wheelSizes[2].size + 4 },
      stiffness: 1,
      length: 0,
      label: "Marker Constraint",
      render: {
        visible: false,
      },
    });
    let wheelConstraint = Constraint.create({
      bodyA: body,
      pointB: { x: backWheelPosition.x, y: backWheelPosition.y },
      stiffness: 1,
      length: 0,
      label: "Wheel Constraint",
    });

    Composite.add(wheel, [
      body,
      gear,
      axisConstraint,
      markerConstraint,
      wheelConstraint,
      marker,
    ]);
  }

  return wheel;
}
